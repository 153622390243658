<template>
	<div class="archives" id="archives">
		<el-row :gutter="20" style="margin-top: 1.04vw;">
			<el-col :md="24" :lg="6" :xl="6" style="margin-bottom: 1.04vw;">
				<div class="info">
					<div class="u-f-justify u-f-column">
						<el-avatar style="width: 4.16vw;height: 4.16vw;" :src="info.avatar"></el-avatar>
						<div class="font_14 m_t">
							{{ info.username }}
							<i class="iconfont icon-nan" v-if="info.sex == 1"></i>
							<i class="iconfont icon-nv" v-if="info.sex == 2"></i>
						</div>
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">电话：</div>
						{{ info.mobile }}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">职称：</div>
						<span v-if="info.job_title==1">无</span>
						<span v-if="info.job_title==2">初级</span>
						<span v-if="info.job_title==3">中级</span>
						<span v-if="info.job_title==4">高级</span>
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">身份：</div>
						{{ info.identity_type==1?'教师':'职员' }}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">部门：</div>
						{{ info.department}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">年级：</div>
						{{info.grade_name||'无'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">入职时间：</div>
						{{ info.jointime ||'无'}}
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">职别：</div>
						<span v-if="info.staff_type==1">正编人员</span>
						<span v-if="info.staff_type==2">临聘</span>
						<span v-if="info.staff_type==3">控股</span>
					</div>
					<div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">任教学科：</div>
						{{info.discipline}}
					</div>
					<!-- <div class="info-item u-f font_14 m_t_15">
						<div style="color: #6A6A6A;">任教班级：</div>
						{{ class_str }}
					</div> -->
				</div>
			</el-col>
			<el-col :md="24" :lg="10" :xl="10" style="margin-bottom: 1.04vw;">
				<div class="class_table" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod" v-if="weekList.length">{{weekList[weeknum-1].name}}课表</div>
						<div class="mouse" style="color: #A0ACB4;" @click="more(1)">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div class="tables" style="color: #555555;">
						<div class="th u-f">
							<div class="b-r b-t p-t-b_12" style="width: 15%;text-align: center;"></div>
							<div class="b-r b-t p-t-b_12 font_blod" style="width: 17%;text-align: center;" v-for="(item, index) in attend_day" :key="index">{{ item.name }}</div>
						</div>
						<div class="tb u-f" v-for="(item, index) in curriculum_time" :key="index">
							<div class="b-r b-t p-t-b_12" style="width: 15%;text-align: center;">{{ item.node }}</div>
							<div
								class="b-r b-t p-t-b_12 u-line-1"
								style="width: 17%;text-align: center;"
								v-for="(items, ind) in item.list"
								:key="ind"
								:class="items.class_name ? 'active' : ''"
							>
								{{ items.class_name || '' }}
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="timecard" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">{{ mothText }}考勤</div>
						<!-- <div class="mouse" style="color: #A0ACB4;">
							更多
							<i class="el-icon-caret-right"></i>
						</div> -->
					</div>
					<div class="timecard-list">
						<div class="timecard-item u-f-justify u-f-column">
							<div>
								<span>{{ attendanceinfo.duty }}</span>
								天
							</div>
							<div class="font_16 m_t_5">出勤</div>
						</div>
						<div class="timecard-item u-f-justify u-f-column">
							<div>
								<span>{{ attendanceinfo.legwork }}</span>
								天
							</div>
							<div class="font_16 m_t_5">外勤</div>
						</div>
						<div class="timecard-item u-f-justify u-f-column">
							<div>
								<span>{{ attendanceinfo.late }}</span>
								次
							</div>
							<div class="font_16 m_t_5">迟到</div>
						</div>
						<!-- <div class="timecard-item u-f-justify u-f-column">
							<div>
								<span>{{ attendanceinfo.absenteeism }}</span>
								天
							</div>
							<div class="font_16 m_t_5">旷工</div>
						</div> -->
						<div class="timecard-item u-f-justify u-f-column">
							<div>
								<span>{{ attendanceinfo.leave }}</span>
								次
							</div>
							<div class="font_16 m_t_5">请假</div>
						</div>
						<div class="timecard-item u-f-justify u-f-column">
							<div>
								<span>{{ attendanceinfo.lack }}</span>
								次
							</div>
							<div class="font_16 m_t_5">缺卡</div>
						</div>
					</div>
				</div>
				<div class="station m_t_20" style="color: #535353;">
					<div class="u-f-item u-f-jsb b-t" style="height: 50px;line-height: 50px;padding: 0 15px;">
						<div class="font_16 font_blod">岗位统计</div>
						<div style="color: #A0ACB4;" class="mouse" @click="more(2)">
							更多
							<i class="el-icon-caret-right"></i>
						</div>
					</div>
					<div style="padding: 15px;">
						<div class="station-list">
							<div class="u-f-item u-f-jsb station-item" v-for="(item, index) in job_class" :key="index" v-if="index < 4">
								<div style="color: #ACB7BD;">
									<i class="iconfont icon-yuandian1"></i>
									{{ item.name }}
								</div>
								<div class="u-f-item">
									<span class="tag" v-if="item.class_adviser">班主任</span>
									<span class="tag" v-if="item.superior">级长</span>
									<!-- <span class="tag">科组长</span> -->
								</div>
							</div>
							<el-empty :image-size="50" v-if="!job_class.length"></el-empty>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :md="24" :lg="8" :xl="8" style="margin-bottom: 1.04vw;">
				<div class="grade" style="color: #535353;">
					<div class="u-f-item u-f-jsb p_15 b-t">
						<div class="font_16 font_blod">考试成绩</div>
						<!-- <div style="color: #A0ACB4;" class="mouse">
							更多
							<i class="el-icon-caret-right"></i>
						</div> -->
					</div>
					<div style="padding: 0 15px 15px 15px;">
						<div class="u-f">
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">学科</div>
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">班级</div>
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">平均分</div>
							<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">
								年级排名
								<i class="el-icon-d-caret"></i>
							</div>
						</div>
						<div style="background-color: #FAFAFA;height: 285px;overflow-y: auto;">
							<div class="u-f" v-for="(item, index) in exam_list" :key="index" v-if="exam_list.length">
								<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">{{ item.discipline_name }}</div>
								<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">{{ item.class_name }}</div>
								<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">{{ item.discipline_avg_score }}</div>
								<div style="width: 25%;text-align: center;height: 40px;line-height: 40px;">{{ item.discipline_avg_score_ranking }}</div>
							</div>
							<el-empty :image-size="100" v-if="!exam_list.length"></el-empty>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :md="24" :lg="16" :xl="16" style="margin-bottom: 1.04vw;">
				<div style="background-color: #FFFFFF;color: #535353;">
					<div class="p_15 b-t"><div class="font_16 font_blod">记录汇总</div></div>
					<div style="padding: 0 40px;"><ve-histogram :data="chartData" :extend="chartExtend" style="height: 340px;"></ve-histogram></div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	props: {
		year:{
			type:String,
			default:''
		}
	},
	data() {
		return {
			chartExtend: {
				legend: {
					show: false
				},
				grid: {
					height: 240
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name:'人数',
					type: 'bar',
					barWidth: 50,
					itemStyle: {
						normal: {
							color: '#58AFFF'
						}
					}
				},
				
			},
			chartData: {
				columns: ['name', 'values'],
				rows: [{name:'',values:''},{name:'',values:''},{name:'',values:''},{name:'',values:''}]
			},
			curriculum_time: [],
			attend_day: [],
			courseData: [],
			info: '',
			class_str: '',
			exam_list: [],
			job_class: [],
			mothText: '',
			moth: '',
			attendanceinfo: '',
			week: '',
			weeknum:'',
			weekList:[]
		};
	},
	created() {},
	mounted() {
		this.getDate();
		this.initData();
		this.getjobWork();
		this.getCourseData();
		this.attendance();
		this.collect();
	},
	methods: {
		// 获取当前时间
		getDate() {
			this.$api.setting
				.getweekList({
					semester_id: window.localStorage.getItem('semester_id')
				})
				.then(res => {
					if (res.data.code == 1) {
						let data = res.data.data.list;
						let arr = [];
						for (let i = 0;i < data.length;i++) {
							arr.push({
								name: data[i],
								id: i + 1
							});
						}
						this.weekList = arr;
						this.weeknum = res.data.data.now;
					}
				});
		},
		// 获取教师详情
		initData() {
			this.$api.teachers
				.teachersIndex({
					teacher_id: this.$route.query.id,
					year_id: this.$route.query.year_id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.info = res.data.data.base_info;
						this.class_str = res.data.data.class_str;
						let exam = res.data.data.exam_list;
						for(let i in exam){
							exam[i].discipline_name = exam[i].discipline_name.toString()
						}
						this.exam_list = exam;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 获取课表数据
		getCourseData() {
			this.$api.teachers.teacherCourse({ teacher_id: this.$route.query.id, week:this.weeknum }).then(res => {
				if (res.data.code == 1) {
					this.attend_day = res.data.data.attend_day;
					let curriculum_time = res.data.data.curriculum_time;
					let list = [];
					if(res.data.data.data){
						list = res.data.data.data
					}
					for(let i=0;i<curriculum_time.length;i++){
						this.$set(curriculum_time[i], 'list', [])
						for(let j=0;j<this.attend_day.length;j++){
							curriculum_time[i].list.push({xq: this.attend_day[j].name, node: curriculum_time[i].node})
							let arr = list.filter(item=>item.attend_day == this.attend_day[j].name && item.node == curriculum_time[i].node);
							if(arr.length){
								curriculum_time[i].list[j].discipline_name = arr[0].discipline_name;
								curriculum_time[i].list[j].class_name = arr[0].class_name;
								curriculum_time[i].list[j].teacher_name = arr[0].teacher_name;
							}
						}
					}
					console.log(curriculum_time)
					this.curriculum_time = curriculum_time;
					console.log(this.curriculum_time);
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		twacherTap(index) {
			this.teacherIndex = index;
		},
		more(index) {
			this.$emit('more', index);
		},
		getjobWork() {
			this.$api.teachers
				.jobWorkYear({
					teacher_id: this.$route.query.id,
					year_id: this.$route.query.year_id
				})
				.then(res => {
					if (res.data.code == 1&&res.data.data!=null) {
						this.job_class = res.data.data;
					}
				});
		},
		// 考勤统计
		attendance() {
			this.$api.teachers
				.attendance({
					date: this.moth,
					teacher_id: this.$route.query.id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.attendanceinfo = res.data.data;
					}
				});
		},
		// 记录汇总
		collect(){
			this.$api.teachers.collect({
				teacher_id:this.$route.query.id
			}).then(res=>{
				if(res.data.code==1){
					this.chartData.rows[0].name = '过程性';
					this.chartData.rows[1].name = '我的住宿学生';
					this.chartData.rows[2].name = '我的荣誉';
					this.chartData.rows[3].name = '我的班级';
					this.chartData.rows[0].values = res.data.data.process;
					this.chartData.rows[1].values = res.data.data.stay;
					this.chartData.rows[2].values = res.data.data.honor;
					this.chartData.rows[3].values = res.data.data.class_num;
					console.log(this.chartData)
				}
			})
		}
	}
};
</script>

<style lang="scss">
#archives {
	.info {
		padding: 30px 30px 0 30px;
		background-color: #ffffff;
		height: 470px;
	}
	.class_table {
		background-color: #ffffff;
		.tables {
			height: 448px;
			.tb {
				overflow-x: hidden;
				overflow-y: auto;
				-ms-overflow-style: none;
				overflow: '-moz-scrollbars-none';
				scrollbar-width: none;
				.active {
					background-color: #eefaf6;
					border: 0.0625rem solid #3fc586;
				}
			}
			.tb::-webkit-scrollbar {
				display: none;
			}
		}
	}
	.timecard {
		background-color: #ffffff;
		.timecard-list {
			display: flex;
			flex-wrap: wrap;
			.timecard-item {
				width: 33%;
				height: 80px;
				div {
					span {
						font-size: 1.25rem;
						color: #4998ff;
					}
				}
			}
		}
	}
	.station {
		background-color: #ffffff;
		.station-list {
			background-color: #fafafa;
			height: 158px;
			padding: 15px;
			.station-item {
				margin-bottom: 15px;
				.icon-yuandian1 {
					color: #1890ff;
					margin-right: 0.3125rem;
				}
				.tag {
					background-color: #e8eaec;
					border-radius: 1.25rem;
					margin-left: 0.625rem;
					padding: 0.1875rem 0.9375rem;
				}
			}
		}
	}
	.grade {
		background-color: #ffffff;
		.el-table th {
			padding: 5px 0;
		}
	}
}
</style>
